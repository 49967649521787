body, .container {
    font-size: 15px;
    font-family: Roboto, sans-serif;
    color: #333333;
    line-height: 17px;
    min-width: 300px;
}

.clr {
    clear: both;
}

a {
    color: #182FDF !important;
    text-decoration: none !important;
}

.article p a, .article ul a {
    display: inline;
}

hr {
    color: #C8D1FA !important;
    opacity: 1 !important;
}

a:hover {
    color: #657CFF !important;
    text-decoration: none;
}

i.fa {
    display: inline;
    margin: 5px;
    text-decoration: none !important;
    line-height: auto;
}

.fa-clock-o {
    margin-left: 0px !important;
}

.home {
    text-transform: uppercase;

}

.home-content {
    margin-top: 5%;
    z-index: 2000 !important;
    position: relative;
}

.home-img {
    padding-right: 0px !important;
}

.home-img img {
    z-index: 1 !important;
    position: relative;
}

.home h1 {
    font-size: 75px;
    color: #002FE7;
    font-weight: bold;
}

.home h4 {
    font-size: 35px;
    color: #000000;
}

.home .kontakty {
    margin-bottom: 20px;
    z-index: 2000 !important;
}

.home .kontakty a {
    font-size: 20px !important;
    color: #FFFFFF !important;
    padding: 25px 45px !important;
    margin-top: 20px;
    font-weight: 500;
}

@media (min-width: 1400px) {

    .home h1 {
        font-size: 75px;
    }

    .home h4 {
        font-size: 35px;
    }

    .home .kontakty a {
        font-size: 23px !important;
        padding: 25px 45px !important;
    }
}

@media (min-width: 1200px) and (max-width: 1399px) {

    .home h1 {
        font-size: 75px;
    }

    .home h4 {
        font-size: 35px;
    }

    .home .kontakty a {
        font-size: 23px !important;
        padding: 20px 40px !important;
    }

    .home-img img {
        max-width: 190%;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {

    .home .kontakty a {
        font-size: 19px !important;
        padding: 18px 36px !important;
    }

    .home-img img {
        max-width: 230%;
        width: 230%;
    }

    .home h1 {
        font-size: 65px;
    }

    .home h4 {
        font-size: 28px;
    }
}

@media (max-width: 991px) {

    .home h1 {
        font-size: 70px;
    }

    .home h4 {
        font-size: 30px;
    }

    .home .kontakty a {
        font-size: 16px !important;
        padding: 15px 22px !important;
    }

    .home-img img {
        max-width: 135%;
    }
}

@media (max-width: 767px) {

    .home .kontakty a {
        font-size: 18px !important;
        padding: 15px 22px !important;
        width: 100% !important;
    }

    .home h1 {
        font-size: 55px;
    }

    .home h4 {
        font-size: 25px;
    }

}

@media (max-width: 575px) {

    .home h1 {
        font-size: 11vw;
        color: #002FE7;
        font-weight: bold;
    }

    .home h4 {
        font-size: 6vw;
        color: #000000;
    }

    .home-img-mob {
        position: absolute;
        top: 0;
        right: 0;
    }

    .home-img-mob img {
        width: 50%;
        float: right;
    }
}


.napisat {
    margin-right: 20px;
}

.home .kontakty a.prezentaciya {
    border-radius: 40px !important;
    background-color: #333333 !important;
}

.home .kontakty a.prezentaciya:hover {
    background-color: #787878 !important;
}

.btn-primary {
    color: #FFFFFF !important;
    font-size: 15px !important;
    background-color: #0033DA !important;
    border: none !important;
    padding: 5px 20px !important;
    border-radius: 5px !important;
}

.btn-primary:hover {
    background-color: #657CFF !important;

}

.btn-primary:focus {
    box-shadow: none !important;
}

header {
    margin-top: 25px;
}

.logo img {
    height: 100%;
    width: auto;
    max-height: 55px;
    margin-top: -5px;
}

.homebtn a {
    display: inline-block;
    background: #0033DA;
    width: 100px;
    height: 31px;
    line-height: 31px;
    text-align: center;
    color: #FFFFFF !important;
    border-radius: 5px;
    margin-right: 40px;
    font-size: 13px;
}

.homebtn a i {
    margin-left: 0;
}

.filtr form button {
    width: 100%;
    margin-right: 0px;
    line-height: 1.4;
}

form.search {
    display: inline-block;
    position: relative;
    font-size: 13px;
    width: calc(100% - 150px);
}

.search input {
    width: 100%;
    height: 31px;
    line-height: 31px;
    padding-left: 10px;
    padding-right: 47px;
    border-radius: 5px;
    border: 1px solid #C8D1FA;
    outline: none !important;
    box-shadow: none !important;
    background: #C8D1F8;
    color: #333333;
    font-size: 13px;
}

.search input:focus {
    border: 1px solid rgba(41,45,50,.07) !important;
    background: #fff !important;

}
.sort .search input:focus::-webkit-input-placeholder {
  color: transparent !important
}

.sort .search input:focus::-moz-placeholder {
  color: transparent !important
}

.sort .search input:focus:-moz-placeholder {
  color: transparent !important
}

.sort .search input:focus:-ms-input-placeholder {
  color: transparent !important
}

.search button {
    position: absolute;
    top: 0;
    right: 0px;
    width: 60px;
    height: 31px;
    border: none;
    background: #032DF3;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    font-size: 19px;
    color: #FFFFFF;
}

.search button:hover, .homebtn a:hover {
    background: #657CFF;
}

.search input::-webkit-input-placeholder {
    color: rgba(0, 47, 231, 0.69);
}

.search input:-moz-placeholder {
    color: rgba(0, 47, 231, 0.69);
}

.search input:-ms-input-placeholder {
    color: rgba(0, 47, 231, 0.69);
}

.lk a {
    margin-left: 15px;
    line-height: 31px;
    font-size: 14px;
}

.soc {
    color: #182FDF;
    font-size: 21px !important;
    line-height: 31px;
    display: inline;
}

.soc a {
}

.lk .soc a {
    font-size: 21px;
}

nav {
    border-bottom: 1px solid #002FE7;
    width: 100%;
    margin-top: 12px;
}

nav ul {
    list-style: none;
    margin-bottom: 0px;
    padding-bottom: 15px;
}

nav ul li {
    display: inline-block;
}

nav ul li a {
    font-size: 14px;
    margin: 0 12px;
}

nav ul li a.ml-0 {
    margin-left: 0px;
}

.navmobile {
    position: relative;
    top: 15px;
}

.navmobile ul {
    list-style: none;
    margin-bottom: 0px;
}

.navmobile ul li {
    background: #EEF1FA;
    border-bottom: 1px solid #8FA5ED;
}

.navmobile ul li a {
    font-size: 16px;
    padding: 10px;
    display: block;
}

.navmobile ul li:hover a {
    color: #FFFFFF !important;
}

.navmobile ul li:hover {
    color: #FFFFFF;
    background: #032DF3;
}

.filtr .homebtn i {
    margin: 0 0 0 5px;
    font-size: 14px;
}

.filtr label {
    line-height: 20px;
}

.filtr h3 {
    text-align: center;
    font-size: 20px;
}

.filtr h5 {
    font-size: 18px;
    font-weight: 500;
    color: #002FE7;
}

.filtr .form-label {
    margin-bottom: 3px;
}

.filtr .form-range {
    width: 92%;
    margin-left: 4%;
}

input.filtr-ot::-webkit-outer-spin-button,
input.filtr-ot::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input.filtr-do::-webkit-outer-spin-button,
input.filtr-do::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.filtr input[type=number] {
    -moz-appearance: textfield;
}

.filtr-ot, .filtr-do {
    width: calc(50% - 17px) !important;
    display: inline-block !important;
    margin-left: 5px;
    margin-right: 5px;
    padding: 0.375rem 0.4rem !important;
}

.filtr .form-check {
    margin-bottom: 8px;
}

.form-check-input[type=checkbox]:focus, .form-check-input[type=checkbox]:hover {
    outline: none;
    box-shadow: none;
    cursor: pointer;
}

.form-check-input:checked {
    background-color: #032DF3 !important;
    border-color: #0033DA !important;
}

.form-control:focus {
    box-shadow: none !important;
    border-color: #0033DA !important;
}

.filtr .form-control, .sort .form-control {
    background-color: #C8D1FA;
    border: 1px solid #C8D1FA;
}

.aside-b {
    border-radius: 5px;
    background: #C9D1F6 !important;
    padding: 20px 20px 30px 20px;
    width: 448px;
    min-height: 367px;
}

.aside-b form label a {
    color: #212529 !important;
}

#modal, #modal2 {
    display: none;
}

.subscribe {
    background: #FFFFFF;
    position: fixed;
    top: calc(50% - 200px);
    left: calc(50% - 200px);
    z-index: 9999;
}

.subscribe button {
    width: 238px;
    height: 37px;
    margin-left: 10%;
    font-size: 16px;
    font-weight: 700;
    margin-left: 85px;
    line-height: 1.0;
}

.subscribe .form-check label {
    font-size: 10px;
    line-height: 14px !important;
}

.subscribe .form-check {
    font-size: 15px;
}

.subscribe h4 {
    text-align: center;
    font-weight: bold;
    font-size: 32px;
    margin-bottom: 25px;
}

.overlay-modal {
    background: #000000;
    opacity: 0.6;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9998;
}

.subscribe label {
    line-height: 23px !important;
}

.subscribe .form-text {
    font-size: 15px;
    color: #000000;
    text-align: center;
}

.subscribe .form-check {
    margin-left: 85px;
}

.subscribe input.form-control {
    width: 238px;
    border: 1px solid #002FE7;
    font-size: 16px;
    color: #002FE7;
    margin: 0 auto;
}

.subscribe input::-webkit-input-placeholder {
    color: #002FE7B0;
}

.subscribe input::-moz-placeholder {
    color: #002FE7B0;
}

/* Firefox 19+ */
.subscribe input:-moz-placeholder {
    color: #002FE7B0;
}

/* Firefox 18- */
.subscribe input:-ms-input-placeholder {
    color: #002FE7B0;
}


.close-subscribe {
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 20px;
    font-weight: bold;
}

section {
    margin-top: 0;
}

section h1, .h1-st {
    font-size: 45px;
    line-height: 45px;
    font-weight: bold;
    padding-bottom: 2.5rem;
    padding-top: 2rem;
}

section p {
    margin-top: 20px;
    margin-bottom: 25px;
    font-size: 20px;
    line-height: 1.2;
    color: #000000;
}

.fa-plus-circle {
    font-size: 35px !important;
}

.sort select {
    width: 200px;
    line-height: 20px;
    font-size: 13px;
    color: #002FE7;
}

.sort .form-group:after {
    content: "";
    display: block;
    border-style: solid;
    border-width: 6px 5px 0 5px;
    border-color: #002FE7 transparent transparent transparent;
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 175px;
    z-index: 1;
    margin-top: -3px;
}

.sort .form-group {
    position: relative;
}

.sort i {
    font-size: 12px;
    margin-left: 3px;
}

.sort a, .sort b {
    display: inline-block;
}

.sort b, .sort a {
    margin-right: 10px;
}

.sort .search {
    width: 50%;
    margin-bottom: 10px;
}

.sort .search input {
     background: rgba(41, 45, 50, 0.07);
    border: 1px solid #F0F0F0;
    padding-right: 77px;
    color: #5D6064;
    font-size: 13px;
}

.sort .search input::-webkit-input-placeholder {
    color: #5D6064;
}

.sort .search input::-moz-placeholder {
    color: #5D6064;
}

/* Firefox 19+ */
.sort .search input:-moz-placeholder {
    color: #5D6064;
}

/* Firefox 18- */
.sort .search input:-ms-input-placeholder {
    color: #5D6064;
}

.sort .search input:focus {
    box-shadow: 0 0 0 0 !important;
}

.sort .search i {
    font-size: 20px;
}

.sort .search button {
    width: 67px;
    background: #292D32;
    color: #FFFFFF;
}

.sort .search button:hover {
    background: #4B535C;
}
.meraforma {
	font-size: 20px !important;
    padding: 11px 30px 12px 30px !important;
	margin-top: 0px !important;
}
.knews .k-dp {
	font-size: 18px;
	margin-right: 1px !important;
}
.relnews h2 {
	border-left: 1px solid #292D32;
    padding-left: 10px;
	}
.article {
    display: block;
    font-size: 15px;
    line-height: 18px;
    border-radius: 5px;
    padding: 10px 35px 35px 35px;
    position: relative;
    z-index: 9996;
    border: 0.915456px solid rgba(0, 47, 231, 0.28);
	box-shadow: 0px 0px 10.07px 2.74637px rgba(0, 47, 231, 0.06);
}

.article a {
    display: block;
    height: auto;
}

.article:hover {
    box-shadow: 0 0 0.25rem 0.25rem rgb(13 110 253 / 25%);
}

.article .col-6 {
    float: left;
}

.ki .inst-img {
	max-height:inherit;
	}
section.ki p {
	font-size:15px;
	}
.ki .article .a-firma {
	line-height: 13px;
}
.ki .article .a-forma {
	line-height: 16px;
}
.meraclass .article .a-cena b {
    font-size: 28px;
    font-weight: 700;
	}
.meraclass .article .a-srok {
    font-size: 28px;
    font-weight: 700;
}
.meraclass .article .a-srok i {
	font-size: 21px;
}
.meraclass .kartochka .a-firma {
	display:block !important;
	margin-top: -72px !important;
}
.knews .kartochka .k-dp {
	line-height: 21px;
	font-size:18px !important;
}
.knews .kn .a-date {
	line-height: 21px;
	font-size:18px !important;
}
.knews .kn-soc i {
	font-size: 29px !important;
    line-height: 12px;
}
.knews .kn .a-date i {
	margin-top: 1px	!important;
}
.relnews .article {
	min-height:516px !important;
}
h5.related {
	font-size: 28px;
}
.relnews h2 {
	height:auto !important;
	border-left: 1px solid #292D32;
    padding-left: 10px;
	}
.relnews .article {
	min-height: auto !important;
}

article .a-firma {
    display: block;
    position: relative;
    margin-right: 260px;
    left: 0px;
    z-index: 9997;
    background: #C7D1FA;
    color: rgba(0, 47, 231, 0.69);
    font-size: 12px;
    padding: 9px 13px;
    line-height: 1.5;
    border-radius: 37px;
    margin-top: -72px;
    margin-left: 35px;
    border: 1px solid #C7D1FA;
}

article .arrow {
    margin-left: 20px;
    margin-bottom: -5px;
}

article .a-firma a:hover {
    color: #0033DA !important;
}

article .a-firma:hover {
    border: 1px solid #0033DA;
}

.article .a-forma {
    display: block;
    border: 1px solid #0033DA;
    font-size: 14px;
    padding: 5px 22px;
    border-radius: 37px;
    color: #0033DA;
    margin-left: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.article .a-date {
    color: #002FE780;
    font-size: 11px;
}

.article .a-srok {
    color: #333333;
    font-size: 25px;
    font-weight: bold;
}

.article .a-srok u {
    text-decoration: none;
    margin-left: 5px;
}

.article .a-cena b {
    font-size: 36px;
    font-weight: 500;
    color: #0033DA;
}

.article .a-cena u {
    text-decoration: none;
    margin-left: 10px;
    font-size: 17px;
    color: #0033DA;
    font-weight: bold;
}

.article h2 {
    font-size: 21px;
    color: #333333;
    font-weight: 500;
}

.inst article {
    float: left;
    width: 48%;
    margin-right: 2%;
}

.inst .article h2 {
     border-left: none;
    padding-left: 0px;
    height: auto;
    overflow: hidden;
}

.news .article h2 {
    height: 78px;
    overflow: hidden;
}

.knews .news .article h2 {
    height: 58px;
    overflow: hidden;
}

.news .article .inst-img img {
    border-radius: 3px;
}

.inst .article .a-univ {
    background: #C7D1FA;
    color: #002FE7B0;
    font-size: 13px;
	line-height:13px;
    padding: 9px 15px;
    border-radius: 37px;
    border: 1px solid #C7D1FA;
    margin-right: 0.6rem;
    margin-bottom: 0.8rem;
}

.inst .article .a-gorod {
    border: 1px solid #0033DA;
    font-size: 13px;
	line-height:13px;
    padding: 5px 19px;
    border-radius: 37px;
    color: #0033DA;
    margin-right: 0.6rem;
    margin-top: auto;
}

.inst .article .a-proj {
    text-align: right;
}

.inst .article {
    padding-bottom: 87px;
    padding-top: 20px;
    min-height: 100%;
}

.inst .article .a-proj b {
    font-size: 35px;
    font-weight: bold;
    color: #333333;
}

.inst .article .a-proj u {
    text-decoration: none;
    margin-left: 10px;
    font-size: 15px;
    color: #333333;
    font-weight: bold;
}

.inst article .homebtn {
    margin-top: -77px;
    z-index: 9997;
    position: relative;
    text-align: center;
}

.inst article .homebtn a {
    width: 153px;
    height: 36px;
    line-height: 36px;
    font-size: 16px;
    margin-right: 35px;
}

.kartochka article {

}

.kartochka .a-firma {
    display: inline-block;
    margin-top: 0px !important;
    margin-left: 0px;
    margin-right: 20px;
}

.kartochka .k-border {
    border: 1px solid #C7D1FA !important;
}

.kartochka .k-dp, .kartochka .k-do {
    position: relative;
    display: inline-flex;
    font-size: 12px;
    margin-top: 30px;
    opacity: .75;
    line-height: 15px;
}
.shared {
    font-size: 18px;
    font-weight: 500;
    line-height: 21.09px;
    padding-right: 10px;
}
.kartochka .k-dp {
    margin-right: 60px;
}

.kartochka .k-dp i, .kartochka .k-do i {
    font-size: 18px;
    opacity: 0.6;
    float: left;
    margin-right: 10px;
}

.kartochka .k-do {
    color: #FF0000;
}

.kartochka .k-srok {
    color: #002FE7;
}

.kartochka .k-cena {
    font-size: 25px;
    color: #0033DA;
    font-weight: bold;
    margin-left: 5px;
}

.kartochka .a-cena {
    margin-left: 5px;
}

.kartochka .article {
    padding: 40px 60px;
}

.kartochka .article h1, .ki-content h2 {
    padding: 0;
    font-weight: 700;
    font-size: 28px;
    line-height: 33px;

}

.kartochka .article .a-forma, .kartochka .article .a-firma {
    font-size: 13px;
    font-weight: 400;

}

.kartochka .article .a-forma {
    padding: 4px 15px 5px;
    font-size: 16px !important;
}

.kartochka .homebtn a {
    display: inline-block;
    font-size: 15px;
    width: auto;
    line-height: 41px;
	width:225px;
	height:41px;
}

.kartochka .homebtn a img {
    margin-left: 13px;
}

.kartochka .homebtn a i {
    margin-left: 10px;
    font-size: 14px;
}

.kartochka .a-firma {
    font-size: 14px;
}

.ki-content {
    position: relative;
}

.ki-img {
    display: inline-block;
    position: absolute;
    top: 50px;
    right: 60px;
}

.ki-content .a-forma {
    display: inline-block;
    padding: 3px 35px 5px 30px;
    margin-left: 0px;
    font-size: 24px;
    font-weight: 300;
}

.ki-content .a-firma, .kn .a-firma {
    padding: 8.5px 30px;
}

.kn .a-firma {
    font-size: 20px !important;
}

.kn .a-firma:hover {
    border: 1px solid #C7D1FA !important;
}

.kn h1 {
    font-size: 36px;
    font-weight: 700;
    text-align: left;
}

.news-img {
    box-shadow: 0px 0px 19px 0px #0000001A;
    filter: drop-shadow(0px 0px 19px rgba(0, 0, 0, 0.1));
    width: max-content;
    margin: 0 auto;
}

.news-img img {
    width: 100%;
    height: auto;
    border-radius: 6px;
}

.kn p, .org p, .kn ul li {
    font-size: 15px!important;
    line-height: 18px;
    font-style: normal;
    font-weight: 400 !important;
    color: #292D32;
}

.progr {
    border: 1px solid #002FE7;
    border-radius: 6px;
    padding: 40px;
}

.progr h3 {
    font-weight: bold;
    font-size: 32px;
    line-height: 37px;
    color: #002FE7;
}

.progr tr td {
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #292D32;
}

.progr .t-date td {
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    color: #002FE7;
    padding-top: 30px;
    padding-bottom: 10px;
}

.progr tr td:first-child {
    padding-right: 20px;
    min-width: 130px;
}

.progr table tr {
    height: 45px;
}

.org p b {
    color: #002FE7;
    font-weight: 400;
}

.org p a {
    display: inline;
    color: #292D32 !important;
}

.org p a:hover {
    color: #555555 !important;
}

.kn .a-date {
    line-height: 36px;
    opacity: 1 !important;
}

.kn-soc b {
    font-weight: 500;
    margin-right: 10px;
    margin-left: 20px;
}

.kn-soc, .kn-soc i {
    opacity: 1 !important;
}

.kn-soc a {
    display: inline-block;
}

.kn-soc a i {
    float: none !important;
}

.related {
    font-size: 36px;
    font-weight: 700;
}

.knews .news .relnews {
    display: inline-block;
    width: 33.3%;
    margin-bottom: 35px;
}

.row .kolonki-3 {
    display: flex;
    width: 33.3%;
    margin-bottom: 25px;
    margin-top: 15px;
}

.row .kolonki-2 {
    display: flex;
    width: 50%;
    margin-bottom: 25px;
    margin-top: 15px;
}

.kolonki-3 h4 {
    text-align: center;
    color: #0033DA;
    margin: 15px;
}

.kolonki-3 span {
    display: block;
    text-align: center;
    color: #333333;
    margin: 15px;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.2;
    font-family: Roboto, sans-serif;
}

.kolonki-2 span {
    display: block;
    color: #333333;
    font-size: 20px;
    margin: 10px 0;
    font-style: normal;
    font-weight: 400;
    line-height: 1.2;
    font-family: Roboto, sans-serif;
}

.kolonki-2 span ul li {
    font-size: inherit !important;
    line-height: 1.2 !important;
}

.ex2 {
    border: 1px solid #CCCCCC;
    padding: 20px 15px 10px 15px;
    border-radius: 10px;
    transition: all .3s ease;
    margin: 10px;
    height: 100%;
}

.ex2:hover {
    box-shadow: 0 10px 18px -2px #CCCCCC;
}

.ex3 h3 {
    margin: 15px 0 15px 0;
}

.ex4 .homebtn a {
    line-height: 1.2;
    padding: 10px 30px;
}

.ex4 .homebtn a:hover {
    opacity: 0.7;
}

.knews .news .article {
    margin-left: 5px;
    margin-right: 5px;
}

.knews .news .article h2 {
    font-size: 16px;
    line-height: 19px;
    margin-top: 25px !important;
}

.knews .news .article .a-univ {
    /*margin-bottom: 30px !important;*/
}

.knews .news .article p {
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
}

.ki-content .a-forma i {
    margin-right: 12px;
    font-size: 20px;
}

.ki-content .inst-img {
    margin: 15px !important;
}

.ki-content2 .article .a-forma {
    padding: 7px 22px 7px 22px;
    font-size: 14px;
}

.ki-content2 .a-firma {
    font-size: 12px !important;
    font-weight: 400;
    margin-right: 250px;
    text-align: center;
    margin-top: 0px !important;
}

.ki-content2 .float-start-url {
    margin-top: -74px !important;
    margin-left: 30px !important;
    margin-bottom: 20px !important;
}

.ki-content2 .article {
    padding: 10px 30px 40px 30px;
}

.ki-img-up {
    display: none;
    top: 0;
    right: 0;
}

.ki-img-down {
    display: block;
}

.kontakty h4 {
    color: #0033DA;
    font-size: 31px;
    font-weight: 700;
    margin-bottom: 30px;
}

.kontakty h5 {
    color: #333333;
    font-size: 31px;
    font-weight: 700;
}

.kontakty p {
    font-size: 17px;
    line-height: 1.3;
    font-weight: 400;
    margin: 5px !important;
    margin-left: 0 !important;
}
.kontakty .foottel, .kontakty .footmail {
	font-weight: 500 !important;
}

.kontakty .foottel a, .kontakty .footmail a {
	font-weight: 400 !important;
}

.kontakty form {
	padding: 30px;
    box-shadow: 0px 0px 12px 4px #002fe708;
    border: 1px solid #292D3212;
    border-radius: 15px;
	}

.kontakty .footadres, .kontakty .footmail {
    margin-bottom: 40px !important;
}

.kontakty .soc i {
    font-size: 30px;
    margin: 15px;
}

.kontakty .soc a {
    float: none;
}

.kontakty form .btn-primary {
    width: 169px;
    height: 42px;
    line-height: 1;
    font-size: 18px!important;
    font-weight: 700;
    border-radius: 9px !important;
}


.kontakty .form input, .kontakty .form textarea {
    background: rgba(41, 45, 50, 0.07);
    box-shadow: 0px 0px 7.37079px 2.45693px rgb(0 47 231 / 3%);
    font-size: 13px;
    font-weight: 400;
    color: #5d6064;
    padding-left: 1rem;
    padding-right: 1rem;
    border: none;
}

.kontakty .form input:focus, .kontakty .form textarea:focus {	
	border: 1px solid rgba(41, 45, 50, 0.07);
	box-shadow: 0px 0px 7.37079px 2.45693px rgba(0, 47, 231, 0.03);
	border-color: rgba(41, 45, 50, 0.07) !important;
}

.kontakty .form input:focus::-webkit-input-placeholder {
  color: transparent
}

.kontakty .form input:focus::-moz-placeholder {
  color: transparent
}

.kontakty .form input:focus:-moz-placeholder {
  color: transparent
}

.kontakty .form input:focus:-ms-input-placeholder {
  color: transparent
}
.kontakty .form textarea:focus::-webkit-input-placeholder {
  color: transparent
}

.kontakty .form textarea:focus::-moz-placeholder {
  color: transparent
}

.kontakty .form textarea:focus:-moz-placeholder {
  color: transparent
}

.kontakty .form textarea:focus:-ms-input-placeholder {
  color: transparent
}


.kontakty .form-control {
    border-radius: 9px;
}

.kontakty .form input {
    height: 42px;
	max-width: 255px;
}

.kontakty textarea {
    height: 78px;
    padding-top: 1.1rem;
    padding-bottom: 1.1rem;
}

.inst-img {
    display: block;
    border: 1px solid #D1D2D3;
    border-radius: 5px;
    padding: 10px 25px;
    text-align: center;
    margin: auto;
	max-height: 190px;
	overflow: hidden;
}

.ki .inst-img img, .knews .news-img img, .news .inst-img img {
    width: 100%;
    height: auto;
}

.inst .inst-img {
    height: 250px;
}

.news .inst-img {
    height: auto;
}

.logonews img {
    float: left;
    max-height: 68px;
    max-width: 190px;
    margin-right: 15px;
}

.pagination {
    margin-bottom: 20px;
    padding-left: calc(var(--bs-gutter-x) * .5) !important;
}

.page-link:focus {
    box-shadow: none !important;
}

.page-link {
    padding: 0.375rem 0.6rem !important;
}

b.page-link {
    font-weight: 600;
    color: #0033DA;
}

.page-link-active {
    font-weight: 600;
}


.team .article {
    padding: 20px !important;
    min-height: 250px;
    margin-left: 0px;
    margin-right: 0px;
    height: 100%;
}

.team .photo {
    display: inline-block;
    width: 115px;
    float: left;
}

.team .photo img {
    width: 115px;
    height: 115px;
    border: 1px solid #d1d2d3;
    border-radius: 5px;
}

.team .photo .soc a {
    display: inline-block;
    margin-left: 5px;
    margin-top: 10px;
}

.team .person {
    display: inline-block;
    margin-left: 15px;
    width: calc(100% - 130px);
    position: relative;
    height: 100%;
    min-height: 180px;
    margin-bottom: 0px;
}

.team .phones {
   position: absolute;
    bottom: 0;
}

.team .zvanie {
    background: #c7d1fa;
    padding: 7px 12px;
    width: 100%;
    font-size: 11px;
    color: #3e61ed;
    border-radius: 6px;
	    line-height: 14px;
}

.team .person h5 {
    font-size: 17px;
    font-weight: 500;
}

.team .person a {
    font-size: 12px !important;
    height: 24px !important;
    line-height: 24px !important;
    border-radius: 14px;
    border: 1px solid #002fe7;
    padding: 0 17px  !important;
    margin-right: 10px;
	margin-top:10px  !important;
}

.team .person a, .news .tags a {
    display: inline-block;
    margin-top: 15px;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    border-radius: 14px;
    border: 1px solid #002FE7;
    padding: 0 25px;
    margin-right: 10px;
}

.team .person a i {
    margin-right: 10px;
}

.news .tags {
    margin-left: 0px;
    margin-right: 20px;
}

.news .article {
    margin-left: 0px;
    margin-right: 20px;
    padding: 20px 25px 25px 25px !important;
}

.news .article h2 {
    font-size: 21px;
    font-weight: 500;
    line-height: 24px;
    margin-top: 45px !important;
	border-left: 1px solid #292D32;
    padding-left: 10px;
	height: auto;
}

.news .article p {
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    text-align: left;
    color: #292D32;
    margin-bottom: 0;
    margin-top: 0px;
}

.news .article .a-univ {
    font-size: 12px;
    padding: 5px 23px;
    margin-right: 0;
    /*margin-bottom: 45px;*/
    margin-left: 7px;
	margin-bottom: 0.35rem;
	line-height: 13px;
}
.a-univ-container {
    margin-left: 80px;
	height: 60px;
    overflow: hidden;
}
.news .article .a-date {
    margin-bottom: 10px;
}

.news .inst-img {
    padding: 3px;
}

.footer-h {
    display: block;
    height: 110px;
    text-align: center;
    margin-bottom: 30px;
    padding: 10px;
}

.footer-h img {
    min-height: 100px;
    width: auto;
    max-width: 100%;
}

.footer-h:hover {
    background: #EEF1FA
}

.footer-h:hover img {
    -webkit-transition: all 0.4s 0s ease, all 0.4s 0s ease;
    -moz-transition: all 0.4s 0s ease, all 0.4s 0s ease;
    -o-transition: all 0.4s 0s ease, all 0.4s 0s ease;
    transition: all 0.4s 0s ease, all 0.4s 0s ease;
    -moz-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    -o-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
}

footer .logo hr {
    margin-left: 10px;
    width: calc(100% - 20px) !important;
}

.footer .logo img {
    margin-right: 30px;
}

.footer-hr {
    height: 2px !important;
    color: #032DF3 !important;
}

.footer {
    background: #292D32;
    padding: 20px 20px 20px 20px;
}

.footer p, .footer h4, .footer h5, .footer a i, .footer a {
    color: #FFFFFF !important;
}

.footer h4 {
    font-size: 15px;
    font-weight: 700;
}

.footer h5 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
}

.footer p {
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 0.2rem;
}

.footadres {
    margin-top: 20px;
}

.footmail {
    margin-bottom: 20px !important;
}

.footer a i {
    font-size: 25px;
    margin: 10px;
}

.footer b {
    font-weight: 400;
}

.footer a:hover, .footer a:hover i {
    color: #C8D1FA !important;
}

.footer button {
    background: #FFFFFF !important;
    color: #333333 !important;
    font-size: 13px !important;
    width: 104px;
    height: 34px;
    font-weight: 400;
}

.footer button:hover {
    background: #C8D1FA !important;
}

.footer .form-floating {
    width: 100%;
}

.footer .form-floating > label {
    padding: 1.2rem 0.75rem;
}

.footer textarea.form-control {
    min-height: 118px;
    width: 96%;
    margin-left: 4%;
    padding: 0.375rem 0.75rem;
}

.footer input, .footer textarea {
    height: 34px;
    font-size: 13px;
    font-weight: 400;
    color: #292D32;
}

.col-lg-6 .textarea-footer {
    margin-left: 5%;
}

.footer button {
    float: right;
}

.footer .col-lg-6 {
    float: left;
}

.footer .soc a {
    float: left !important;
}

.firstsoc {
    margin-left: -10px !important;
}

.footer input::-webkit-input-placeholder {
    color: #292D32;
}

.footer input::-moz-placeholder {
    color: #292D32;
}

/* Firefox 19+ */
.footer input:-moz-placeholder {
    color: #292D32;
}

/* Firefox 18- */
.footer input:-ms-input-placeholder {
    color: #292D32;
}

.footer textarea::-webkit-input-placeholder {
    color: #292D32;
}

.footer textarea::-moz-placeholder {
    color: #292D32;
}

/* Firefox 19+ */
.footer textarea:-moz-placeholder {
    color: #292D32;
}

/* Firefox 18- */
.footer textarea:-ms-input-placeholder {
    color: #292D32;
}

.hr-foot {
    border-top: 1px solid #FFFFFF;
    padding-top: 20px;
    color: #FFFFFF;
    font-weight: 400;
    font-size: 13px;
    margin-top: 35px !important;
}
.tag-news {
    height: 41px;
}
.tag-news a {
    line-height: 23.44px;
    color: rgba(0, 47, 231, 0.69);
}
.amount-mera {
    font-size: 28px;
    font-weight: 700;
    line-height: 32.81px;
    color: rgba(0, 47, 231, 1);
    margin-left: 0 !important;
}
.mfm {
    height: 41px;
    padding: 9px 30px 12px 30px !important;
}
.mfm span {
    line-height: 23.44px;
    font-size: 20px;
    font-weight: 400;
}
.actual-instit {
    color: rgba(0, 47, 231, 0.69);
}
.vk-logo-home img{
    height: 18px;
    margin-top: -3px;
}
.vk-logo-home:hover{
    opacity: 0.6;
}
.vk-logo-contacts img{
    height: 23px;
    margin-top: -11px;
}
.vk-logo-contacts:hover{
    opacity: 0.6;
}
.vk-logo-footer img {
    height: 21px;
    margin-right: 10px;
    margin-top: -5px;
}
.vk-logo-footer:hover img{
    -webkit-filter: sepia(1) hue-rotate(-900deg) saturate(146);
    filter: sepia(1) hue-rotate(-900deg) saturate(146)
}
@media (min-width:1200px) {
	.news .article {min-height:668px !important}
	.clr-kn-h1 {clear: inherit !important;}
}

@media (min-width: 1200px) and (max-width: 1399px) {
    .inst .article h2 {
        font-size: 21px;
        height: auto;
    }
}

@media (max-width: 1400px) and (min-width: 993px) {
    .a-proj {
        margin-top: 1.5rem
    }

    article .a-firma {
        text-align: center
    }
}

@media (max-width: 1199px) and (min-width: 576px) {
    .article .a-cena u {
        margin-left: 5px;
        font-size: 12px;
    }

    .article .a-cena b {
        font-size: 28px;
    }

    .article .a-srok {
        font-size: 25px;
    }

    .ki-content2 .float-start-url {
        margin-top: -115px !important;
        margin-left: 30px !important;
        margin-bottom: 20px !important;
        margin-right: 20px;
    }

    .soc a {
        margin-left: 7px;
        float: right;
        margin-right: 7px;
    }

    .team .soc a {
        margin-left: 4px !important;
        float: none !important;
        margin-right: 0px !important;
    }

    .lk a {
        margin-right: 15px;
        margin-left: 0px;
    }

    nav {
        border-top: 1px solid #032DF3;
    }

    nav ul {
        padding-top: 15px;
    }

   .team .article {
		padding-top: 35px;
		min-height: auto;
		margin-left: 0;
		margin-right: 0;
		height: 100%;
	}
	.team .person {
		height: auto !important;
		min-height: auto !important;
		}
	.team .phones {
		position: relative !important;
	}

}

@media (max-width: 1199px) {
    .knews .news .relnews, .row .kolonki-3, .row .kolonki-2 {
        width: 50%;
    }


    .inst .article h2 {
        height: auto;
        overflow: hidden;
    }

    .news .article h2 {
        height: 78px;
        overflow: hidden;
    }
}

@media (max-width: 992px) {
    .ki article .article h2 {
        margin-top: 2rem !important;
        margin-bottom: 2rem !important;
    }

    article .a-firma {
        margin-top: -178px;
        margin-right: 35px;
        text-align: center;
    }

    article .article h2 {
        margin-bottom: 84px !important;
    }

    .inst .article h2 {
        height: auto;
        overflow: visible;
        margin-bottom: 2rem !important;
    }

    .news .article h2 {
        height: 104px;
        overflow: hidden;

    }

    .knews .news .article h2 {
        height: auto;
        overflow: visible;
    }

    .related {
        font-size: 22px;
        text-align: center;
    }

    .knews .news .relnews, .row .kolonki-3, .row .kolonki-2 {
        width: 100%;
    }

    .logonews img {
        float: right;
        margin-right: 0px;
    }

    .article h2 {
        font-size: 19px;
    }

    .footer textarea.form-control {
        width: 100%;
        margin-left: 0%;
    }

    .col-lg-6 .textarea-footer {
        margin-left: 0;
    }

    .footer form button {
        width: 100%;
    }

    .sort .search {
        width: 100%;
    }

    .inst article {
        width: 100%;
        margin-right: 0;
    }

    .article .a-cena u {
        margin-left: 5px;
        font-size: 12px;
    }

    .article .a-cena b {
        font-size: 28px;
    }

    .article .a-srok {
        font-size: 25px;
    }

    .kn .a-firma {
        font-size: 16px !important;
    }

    .progr h3 {
        font-size: 20px !important;
    }

    .progr tr td {
        font-size: 16px !important;
    }
}

@media (max-width: 767px) {

    .knews .news .article h2, .news .article h2 {
        height: auto;
        overflow: visible;
    }

    .ki-img-up {
        display: block;
    }

    .ki-img-down {
        display: none;
    }

    .ki .article {
        padding: 20px;
    }

    .ki-content2 .article {
        padding: 10px 20px 90px 20px !important;
    }

    .ki-content2 .float-start-url {
        margin-top: -78px !important;
        margin-left: 20px !important;
        margin-right: 20px !important;
    }

    .ki-content2 .a-firma {
        margin-right: 0px;
    }

    .ki-content .a-firma {
        padding: 9px 15px;
        margin-bottom: 10px !important;
    }

    .ki-content h2 {
        margin-top: 2rem !important;
        margin-bottom: 2rem !important;
    }

    .news .article {
        margin-left: 0px;
        margin-right: 0px;
    }

    .news .tags {
        margin-left: 00px;
        margin-right: 00px;
    }

    .kontakty .form input {
        height: 49px;
    }

    .kontakty .form input, .kontakty .form textarea {
        font-size: 16px;
    }

    .kontakty form .btn-primary {
        width: 100%;
        height: 49px;
        font-size: 18px !important;
        margin-top: 0 !important;

    }

    .kontakty .btn-primary {
        width: 100%;
        height: 49px;
        line-height: 1.0;
        font-size: 20px !important;
    }

    .soc a {
        float: right;
    }

    form.search {
        width: 100%;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .menubar a {
        color: #032DF3;
        font-size: 37px;
        float: right;
    }

    .lk a {
        margin-left: 5px;
        line-height: 18px;
        display: block;
    }

    section {
        margin-top: 0px !important;
    }

    section h1, .h1-st {
        font-size: 27px;
        line-height: 32px;
        text-align: center;
    }

    section p {
        text-align: justify;
    }

    .fa-plus-circle {
        font-size: 25px !important;
    }

    .article .a-srok {
        color: #333333;
        font-size: 19px;
        font-weight: bold;
    }

    .article .a-cena b {
        font-size: 25px;
        font-weight: bold;
        color: #0033DA;
    }

    .article .a-cena u {
        text-decoration: none;
        margin-left: 10px;
		font-size: 18px;
        color: #0033DA;
        font-weight: bold;
    }

    article .a-firma {
        margin-top: -165px;
        padding: 5px 12px;
    }

    .kartochka h1 {
        text-align: left;
        font-size: 22px !important;
    }

    section p {
        font-size: 16px;
    }

    .kartochka .k-dp, .kartochka .k-do {
        font-size: 14px;
    }

    .kartochka .article .a-forma, .kartochka .article .a-firma, .ki-content .a-forma i {
        font-size: 13px;
        font-weight: 400;
    }

    .kartochka .article .a-forma {
        padding: 9px 14px 11px;
    }
	.kartochka .article .a-forma a {
		font-size: 16px;
	}
    .ex4 .homebtn a {
        font-size: 16px !important;
    }

    .kartochka .homebtn a {
        display: block;
        font-size: 13px;
        text-align: center;
        margin-right: 0px;
        padding: 10px;
    }

    .kartochka .a-cena b, .kartochka .k-cena {
        font-size: 20px;
    }

    .kartochka .a-cena u {
        font-size: 15px;
    }

    .kartochka .homebtn a i {
        font-size: 12px;
    }

    .progr {
        padding: 40px 10px;
    }

}

@media (max-width: 576px) {
    .kn .a-firma {
        font-size: 13px !important;
        margin-right: 0px;
        margin-left: 10px;
    }

    .relnews .article {
        padding: 10px !important;
    }

    .relnews .article .a-univ {
        font-size: 12px;
        padding: 3px 14px;
    }

    .progr h3 {
        font-size: 16px !important;
        line-height: 20px;
    }

    .progr tr td:first-child {
        padding-right: 0px;
        min-width: 100px;
    }

    .progr tr td {
        font-size: 15px !important;
    }

    .kn-soc b {
        display: none;
    }

    .kn-soc a i {
        font-size: 20px !important;
    }

    .team .article {
        margin-left: 0px;
        margin-right: 0px;
        padding: 25px 15px;
    }

    .team .photo {
        display: block;
        float: none;
        width: 100%;
    }

    .team .photo img {
        width: 100%;
        height: auto;
    }

    .team .phones {
        position: relative;
    }

    .team .person {
        display: block;
        margin-left: 0px;
        width: 100%;
        margin-bottom: 0px;
    }

    .team .person a {
        padding: 0 15px;
    }

    .team .soc {
        display: block;
        margin-left: 15px;
        margin-bottom: 50px;
    }

    .team h5 {
        margin-top: 13px;
        margin-bottom: 13px;
    }

    .sort a {
        display: block;
    }

    .logonews img {
        display: none;
    }

    .logo img {
        height: 40px;
    }

    .footer-h {
        display: block;
        height: 70px;
        text-align: center;
        margin-bottom: 30px;
        padding: 10px;
    }

    .footer-h img {
        min-height: 60px;
        width: auto;
        max-width: 100%;
    }

    .aside-b {
        padding: 20px;
        width: 100%;
        height: auto;
    }

    .subscribe {
        top: 0;
        left: 0;
    }

    .subscribe .form input, .subscribe .form-check, .subscribe button {
        width: 100%;
        margin-left: 0;
    }

    .a-proj {
        margin-top: 1.5rem;
        padding-left: 20px;
    }

    .kartochka .article {
        padding: 20px;
    }

    .kontakty h4, .kontakty h5, .kontakty .soc i {
        font-size: 22px;
    }

    .kontakty p {
        font-size: 16px;
    }
}

@media (max-width: 480px) {
    .inst .article h2 {
        margin-bottom: 2rem !important;
    }

    article .article h2 {
        margin-bottom: 96px !important;
    }

    article .a-firma {
        margin-top: -180px;
        padding: 5px 15px;
    }

    .article .a-cena b {
        font-size: 21px;
    }

    .rc-slider-rail, .rc-slider-track {
        color: #C7D1FA;
        background-color: #C7D1FA;
        position: absolute;
        height: 6px;
        border-radius: 6px;
    }

    .rc-slider-step {
        position: absolute;
        width: 100%;
        height: 6px;
        background: transparent;
    }

    .rc-slider-handle {
        position: absolute;
        width: 16px;
        height: 16px;
        cursor: pointer;
        cursor: -webkit-grab;
        margin-top: -5px;
        cursor: grab;
        border-radius: 50%;
        border: 2px solid #002FE7;
        background-color: #002FE7;
        touch-action: pan-x;
    }

    .rc-slider-handle:hover {
        background-color: #657CFF;
    }
}

.disabled-link {
    pointer-events: none;
}

.selected-link {
    font-weight: bolder;
}
